@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.ride-capacity {
    margin: 0;
    padding: cssvar(spacing-2) cssvar(spacing-4) cssvar(spacing-4)
        cssvar(spacing-4);
    border: 1px solid cssvar(primary-line-color);
    background-color: cssvar(primary-bg-color);

    &__ride {
        margin-bottom: cssvar(spacing-4);
    }

    &__table-wrapper {
        overflow: auto;
        td:nth-of-type(2n) {
            min-width: 54px;
        }
    }

    &__station {
        &--closed {
            color: cssvar(danger-dark-color);
        }
    }
    &__cell-value {
        display: inline-flex;
        flex-flow: column;
        vertical-align: middle;
        line-height: cssvar(line-height-small);
        margin-left: cssvar(spacing-1);
        &--red {
            color: cssvar(danger-dark-color);
        }
        &--green {
            color: cssvar(success-dark-color);
        }
    }
    &__legend {
        margin-top: cssvar(spacing-2);
    }
}

.rides-picker {
    &__top {
        display: flex;
    }

    &__select-options {
        padding: cssvar(spacing-1) 0 0 cssvar(spacing-2);
    }

    &__select-sep {
        margin-left: -4px;
        margin-right: 6px;
        color: cssvar(primary-brand-color);
    }

    &__rides {
        display: flex;
        flex-wrap: wrap;
        max-width: 340px;
        padding-bottom: cssvar(spacing-1);
    }

    &__ride-check {
        margin: cssvar(spacing-1) cssvar(spacing-2) cssvar(spacing-1) 0;
    }
}

.temporary-banner {
    width: 59%;
    margin: cssvar(spacing-2) auto;
    padding: cssvar(spacing-3) 86px;
    text-align: center;
    font-size: 17px;
    border-radius: 15px;
    background-color: #ff943b;
}
